import React, { ReactElement, Fragment } from 'react';
import {
  content,
  Section,
  ImageSection,
  FAQComponent,
  CompositeList,
  Banner,
  MasonrySection,
  injectSheet,
  StationPickerSection,
  ProgramSection,
  FragmentJackpotSection,
  UniversalVotingEmbed,
  Frequency,
  AudioEmbedPlayer,
  ECommerceAuctionWidget,
  ChartList,
  TextFrame
} from '@imports';
import { banners } from '@banners';
import {
  COMPONENT_SECTION_TYPE,
  CompositeListType,
  ContentId
} from '@type/SingleSection.enum';
import {
  ImageModel,
  ContentfulModel,
  OverviewSectionItemModel,
  ContentHeaderSectionType
} from '@type/index';
import { ThemeProps } from '@theme/ThemeProps.model';

import VideoSectionPlayer from '../VideoSectionPlayer/VideoSectionPlayer';
import styles from '../Overview.styles';
import ContentfulRichText from '../../ContentfulRichText/ContentfulRichText';

import { contentIdHeaderSection } from './SingleSection.utils';

type Props = {
  section: ContentfulModel<OverviewSectionItemModel>;
  index: number;
  fallbackImage: ImageModel;
  showLabelOnArticleCards: string;
  landingPage: boolean;
};

type SingleSectionProps = ThemeProps & Props;

const SingleSection: React.FC<SingleSectionProps> = ({
  fallbackImage,
  showLabelOnArticleCards,
  section,
  index,
  classes,
  landingPage,
  theme
}): ReactElement | null => {
  if (!section?.fields) return null;

  let sectionType: ReactElement | null = null;
  let inGrid = false;
  let hasBannerBeneath = false;
  let hasBannerAbove: boolean;
  const contentId: string = section.sys.contentType.sys.id;
  switch (contentId) {
    case ContentId.SECTION:
      inGrid = true;

      sectionType = (
        <Section
          section={section}
          masonrySection={
            <MasonrySection
              fallbackImage={fallbackImage}
              section={section}
              showLabelOnArticleCards={showLabelOnArticleCards}
            />
          }
          content={content}
          showLabelOnArticleCards={showLabelOnArticleCards}
        />
      );
      break;
    case ContentId.IMAGE_SECTION:
      if (section.fields) {
        inGrid = true;
        sectionType = <ImageSection section={section} />;
      }
      break;
    case ContentId.HEADER_SECTION:
      const result: ContentHeaderSectionType | null = contentIdHeaderSection({
        section,
        theme,
        fallbackImage
      });
      inGrid = result?.inGrid || inGrid;
      sectionType = result?.sectionType || null;
      hasBannerBeneath = Boolean(index === 0);
      break;
    case ContentId.COMPONENT_SECTION:
      switch (section.fields.component) {
        case COMPONENT_SECTION_TYPE.frequencies:
          inGrid = true;
          sectionType = <Frequency content={section} />;
          break;
        case COMPONENT_SECTION_TYPE.station_picker:
          inGrid = true;
          sectionType = <StationPickerSection section={section} />;
          break;
        case COMPONENT_SECTION_TYPE.program_guide:
          inGrid = true;
          sectionType = (
            <ProgramSection
              section={section.fields}
              variant="onAir"
              profile="radio-brand-web"
              titleWithBackground
              slidesWithBackgroundImgs
            />
          );
          break;
        case COMPONENT_SECTION_TYPE.auction_widget:
          inGrid = true;
          sectionType = <ECommerceAuctionWidget content={section} />;
          break;
        case COMPONENT_SECTION_TYPE.fragmentjackpot_search:
        case COMPONENT_SECTION_TYPE.fragmentjackpot_answers_with_datetime:
        case COMPONENT_SECTION_TYPE.fragmentjackpot_answers_without_datetime:
          inGrid = true;
          sectionType = (
            <FragmentJackpotSection
              content={section}
              type={section.fields.component}
            />
          );
          break;
        default:
          sectionType = <h1>{section.sys.contentType.sys.id}</h1>;
          break;
      }
      break;
    case ContentId.VOTING_LIST:
      inGrid = false;
      sectionType = <UniversalVotingEmbed content={section} />;
      break;
    case ContentId.COMPOSITE_LIST:
      if (section.fields.type === CompositeListType.FAQ) {
        inGrid = true;
        sectionType = (
          <FAQComponent
            section={section}
            renderRte={(data) => (
              <ContentfulRichText data={data} fallbackImage={fallbackImage} />
            )}
          />
        );
      } else if (
        section.fields.type === CompositeListType.CONTEXTUAL_TEXT_CARD
      ) {
        inGrid = true;
        sectionType = (
          <CompositeList
            section={section}
            renderRte={(data) => (
              <ContentfulRichText data={data} fallbackImage={fallbackImage} />
            )}
          />
        );
      } else {
        sectionType = <h1>{section.sys.contentType.sys.id}</h1>;
      }
      break;
    case ContentId.AUDIO_EMBED:
      if (section.fields.omnyStudioType === 'playlist') {
        inGrid = true;
        sectionType = <AudioEmbedPlayer content={section} />;
      }
      break;
    case ContentId.CHART_LIST:
      if (section?.fields?.source?.fields?.file?.url) {
        inGrid = true;
        sectionType = (
          <ChartList source={section.fields.source.fields.file.url} />
        );
      }
      break;
    case ContentId.VIDEO_SECTION:
      inGrid = true;
      sectionType = (
        <VideoSectionPlayer
          content={section}
          getVideosByCategory={content.getVideosByCategory}
          getLatestVideosByCategory={content.getLatestVideosByCategory}
          fallbackImage={fallbackImage}
        />
      );
      break;
    case ContentId.TEXT_FRAME:
      inGrid = true;
      sectionType = (
        <TextFrame
          content={section}
          renderRte={(data) => (
            <ContentfulRichText data={data} fallbackImage={fallbackImage} />
          )}
        />
      );
      break;
    default:
      sectionType = null;
      break;
  }

  hasBannerBeneath = hasBannerBeneath && landingPage;
  hasBannerAbove = Boolean(index === 0) && !hasBannerBeneath && landingPage;

  return (
    <Fragment key={section.sys.id}>
      {hasBannerAbove && <Banner content="homepage" banners={banners} />}
      {/* UTAG CHANGE: added data-section-title attribute */}
      <div data-section-title={section.fields.title} className={classes.holder}>
        {inGrid ? (
          <div className={classes.wrapper}>{sectionType}</div>
        ) : (
          sectionType
        )}
      </div>
      {hasBannerBeneath && <Banner content="homepage" banners={banners} />}
    </Fragment>
  );
};

export default injectSheet(styles)(SingleSection);
