import React from 'react';
import SvgIcon from '@talpanetwork/talpa-publishing-core/SvgIcon';

const mobile = (props) => (
  <SvgIcon {...props} variant="HeaderSectionSvg" viewBox="0 0 356 39">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M355.112 38.995H0V.01c141.515-.012 301.907-.012 333.181 0 4.19.789 6.407 2.003 8.044 4.061 1.588 1.997 3.36 5.351 4.8 9.334l9.087 25.591z"
    />
  </SvgIcon>
);

const tablet = (props) => (
  <SvgIcon {...props} variant="HeaderSectionSvg" viewBox="0 0 615 160">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M615.051 160H0L.004.036c248.25-.048 418.206-.048 537.763 0 .64 0 1.27.048 1.889.145 8.722 1.85 14.335 4.925 18.482 10.138 4.02 5.056 8.508 13.551 12.154 23.638L615.052 160z"
    />
  </SvgIcon>
);

const pc = (props) => (
  <SvgIcon {...props} variant="HeaderSectionSvg" viewBox="0 0 1216 256">
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M1216 256H0L.007.058C397.05-.02 901.179-.02 1092.394.058c1.025 0 2.033.077 3.02.23 13.951 2.963 22.93 7.882 29.561 16.223 6.43 8.09 13.607 21.681 19.438 37.82L1216 256z"
    />
  </SvgIcon>
);

mobile.propTypes = SvgIcon.propTypes;
tablet.propTypes = SvgIcon.propTypes;
pc.propTypes = SvgIcon.propTypes;

mobile.defaultProps = SvgIcon.defaultProps;
tablet.defaultProps = SvgIcon.defaultProps;
pc.defaultProps = SvgIcon.defaultProps;

const SvgHeaderSection = {
  mobile,
  tablet,
  pc
};

export default SvgHeaderSection;
